import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Dedicated = lazy(() => import("./pages/Dedicated"));
const Reseller = lazy(() => import("./pages/Reseller"));
const Prime = lazy(() => import("./pages/Prime"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const GroupChat = lazy(() => import("./pages/GroupChat"));

const Email1 = lazy(() => import("./pages/post/Email1"));
const Email2 = lazy(() => import("./pages/post/Email2"));
const Email3 = lazy(() => import("./pages/post/Email3"));
const Email4 = lazy(() => import("./pages/post/Email4"));
const Email5 = lazy(() => import("./pages/post/Email5"));

function App() {
  return (
    <Suspense>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/cosmic-clavstore" element={<Home />} />
        <Route exact path="/dedicated" element={<Dedicated />} />
        <Route exact path="/reseller" element={<Reseller />} />
        <Route exact path="/prime" element={<Prime />} />
        <Route exact path="/cosmic-thankyou" element={<ThankYou />} />
        <Route exact path="/group-chat" element={<GroupChat />} />
        <Route exact path="/14-minutes-become-expert" element={<Email1 />} />
        <Route exact path="/import-export" element={<Email2 />} />
        <Route exact path="/category-variant-payment" element={<Email3 />} />
        <Route exact path="/tindahan-test-run" element={<Email4 />} />
        <Route exact path="/personal-vs-admin" element={<Email5 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
